:root {
  --tag-bg-color: #e6f7ff;
  --tag-border-color: #91d5ff;
  --tag-delete-text-color: #666;
  --tags-list-bg-color: #096dd9;
  --tag-delete-hover-text-color: #cc0000;
  --tag-delete-hover-bg-color: #ffdddd;
  --white-color: #fff;
  --tag-lookup-text-color: #eee;
  --tag-lookup-li-color: #eeeeff;
}

.login-container {
  width: 100%;
  margin-left: auto;
  box-sizing: border-box;
  margin-right: auto;
  display: block;
  max-width: 600px;
  padding-left: 24px;
  padding-right: 24px;
}
.page-content {
  background: #f0f2f5;
  min-height: calc(100vh - 90px);
  padding: 30px;
  padding-top: 12px;
  margin: auto;
  width: 100%;
  max-width: 1120px;
}

.page-content .input-card {
  width: 100%;
  min-height: calc(85vh - 120px);
}

.page-content .ant-select-single {
  float: right;
}

.tags-list {
  padding: 0;
  margin-top: 12px;
}

.tags-list li {
  color: var(--tags-list-bg-color);
  background-color: var(--tag-bg-color);
  border: 1px solid var(--tag-border-color);
  cursor: pointer;
  float: left;
  list-style-type: none;
  margin-right: 5px;
  padding: 3px 5px;
  border-radius: 5px;
  margin-bottom: 8px;
}

.tags-list li .delete {
  background-color: transparent;
  border-style: none;
  color: var(--tag-delete-text-color);
  display: none;
  font-size: 11px;
  height: 11px;
  margin-left: 5px;
  padding: 0;
  width: 11px;
}

.tags-list li:hover {
  background-color: var(--tag-delete-hover-bg-color);
  color: var(--tag-delete-hover-text-color);
  text-decoration: line-through;
}

.tags-list:after {
  clear: both;
  content: '';
  display: block;
}

.submit-btn {
  background-color: #294c7c !important;
  border: 1px solid #294c7c !important;
}

.antd-loadicon {
  position: absolute !important;
  top: 40%;
  left: 50%;
  z-index: 1;
}

.input-card h4 {
  font-family: GlacialInDifference;
}

.bold {
  font-family: GlacialIndifferenceBold !important;
}

.input-card .ant-radio-group {
  display: grid;
}

.bg-white {
  background: white !important;
}

.btn-submit {
  background-color: #294c7c !important;
  border-color: #294c7c !important;
  margin-top: 60px;
}

.rmsc {
  font-family: GlacialInDifference;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  cursor: pointer;
  min-width: 520px;
  max-width: 520px;
}

.rmsc .dropdown-heading {
  height: 32px;
}
