.report-by-type {
  font-family: GlacialInDifference;
}
.report-by-type,
.report-by-type .ant-table-wrapper {
  /* justify-content: end; */
  width: 100%;
  /* font-size: 1px; */
}

.report-by-type .ant-table-thead > tr > th {
  font-family: GlacialInDifference;
  font-size: 14px;
  /* text-transform: capitalize; */
  color: rgb(55, 105, 152);
}

.report-by-type img {
  width: 22px;
  height: 22px;
  margin-right: 8px;
}

.report-by-type button {
  height: 38px;
  font-size: 15px;
  margin-bottom: 20px;
}

.report-by-type h5 {
  font-size: 17px !important;
  margin-left: 12px;
  font-weight: 500 !important;
}

.report-by-type td.text {
  font-size: 16px !important;
}

.report-by-type .btn {
  display: block;
  float: right;
}

.report-by-type h5 {
  text-transform: inherit;
  margin-bottom: 0px!important;
}
